@import "~@ng-select/ng-select/themes/default.theme.css";

.cursor {
  &--pointer {
    cursor: pointer;
  }
}

.card-download {
  border: 1px solid #9bc9ff;
  padding: .4rem;
  width: 65%;
  margin: 0 auto;
  border-radius: 4px;
  cursor: pointer;
  transition: all .2s ease-in-out;

  & strong {
    color: #1e81ce;
  }

  &:hover {
    background-color: #eff6ff;
  }
}

.download-icon {
  font-size: 3.3rem;
  color: #1e81ce;
}

.download-img {
  vertical-align: middle;
}


.select2-container--classic .select2-selection--single,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--multiple {
  border-color: rgba(0, 0, 0, 0.1);
  height: 40px;
  color: #6a7a8c;
  line-height: 40px;
}

.select2-container--default .select2-selection--multiple {
  line-height: 27px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: #137eff;
  border-color: #137eff;
  color: #fff;
}

.form--footer {
  padding: 16px;
  background: #f5f5f5;
  border-top: 1px solid #e4e4e4;
}

.text-justify {
  text-align: justify;
}

ng-select .is-invalid {
  border-color: #dc3545;
}

.required-star {
  color: #dc3545;
}

.bolder {
  font-weight: 600;
}

.cfix,
.topbar:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

code,
kbd,
pre,
samp {
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

select {
  text-transform: none;
}

button {
  overflow: visible;
}

button,
input,
select,
textarea {
  max-width: 100%;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
  opacity: .5;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 0.4375em;
  padding: 0;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-outer-spin-button,
input[type="week"]::-webkit-inner-spin-button,
input[type="week"]::-webkit-outer-spin-button,
input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #d1d1d1;
  margin: 0 0 1.75em;
  min-width: inherit;
  padding: 0.875em;
}

fieldset> :last-child {
  margin-bottom: 0;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

optgroup {
  font-weight: bold;
}

/**
  * 2. GLOBAL STYLES
  * -----------------------------------------------------------------------------
  */
/*1.1 GENERAL STYLES*/
body,
button,
input,
select,
textarea {
  color: #222222;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.6;
}

h1,
h2,
.banner-page .title-page,
h3,
.jumbo-heading h2,
.jumbo-heading .banner-page .title-page,
.banner-page .jumbo-heading .title-page,
h4,
.lead,
h5,
.section-subheading,
h6 {
  clear: both;
  font-weight: 700;
  margin: 0;
  text-rendering: optimizeLegibility;
}

p {
  margin: 0 0 1.75em;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  border: 0 solid #1a1a1a;
  border-left-width: 4px;
  color: #686868;
  font-size: 16px;
  font-style: italic;
  line-height: 1.6;
  margin: 0 0 1.4736842105em;
  overflow: hidden;
  padding: 0 0 0 1.263157895em;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

blockquote p {
  margin-bottom: 1.4736842105em;
}

blockquote cite,
blockquote small {
  color: #1a1a1a;
  display: block;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
}

blockquote cite:before,
blockquote small:before {
  content: "\2014\00a0";
}

blockquote em,
blockquote i,
blockquote cite {
  font-style: normal;
}

blockquote strong,
blockquote b {
  font-weight: 400;
}

blockquote> :last-child {
  margin-bottom: 0;
}

blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  color: #041e42;
}

address {
  font-style: italic;
  margin: 0 0 1.75em;
}

code,
kbd,
tt,
var,
samp,
pre {
  font-family: Inconsolata, monospace;
}

pre {
  border: 1px solid #d1d1d1;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3125;
  margin: 0 0 1.75em;
  max-width: 100%;
  overflow: auto;
  padding: 1.75em;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  background-color: #d1d1d1;
  padding: 0.125em 0.25em;
}

abbr,
acronym {
  border-bottom: 1px dotted #d1d1d1;
  cursor: help;
}

mark,
ins {
  background: #007acc;
  color: #fff;
  padding: 0.125em 0.25em;
  text-decoration: none;
}

big {
  font-size: 125%;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  background: #ffffff;
  /* Fallback for when there is no custom background color defined. */
}

hr {
  background-color: #d1d1d1;
  border: 0;
  height: 1px;
  margin: 0 0 1.75em;
}

ul,
ol {
  margin: 0 0 1.75em 1.25em;
  padding: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
  margin-left: 1.5em;
}

li>ul,
li>ol {
  margin-bottom: 0;
}

dl {
  margin: 0 0 1.75em;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 0 1.75em;
}

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */
  vertical-align: middle;
}

del {
  opacity: 0.8;
}

table,
th,
td {
  border: 1px solid #d1d1d1;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 1.75em;
  table-layout: fixed;
  /* Prevents HTML tables from becoming too wide */
  width: 100%;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: 700;
}

td {
  border-width: 0 1px 1px 0;
}

th,
td {
  padding: 0.4375em;
}

/* Placeholder text color -- selectors need to be separate to work. */
::-webkit-input-placeholder {
  color: #686868;
  font-family: "Open Sans", sans-serif;
}

:-moz-placeholder {
  color: #686868;
  font-family: "Open Sans", sans-serif;
}

::-moz-placeholder {
  color: #686868;
  font-family: "Open Sans", sans-serif;
  opacity: 1;
  /* Since FF19 lowers the opacity of the placeholder by default */
}

:-ms-input-placeholder {
  color: #686868;
  font-family: "Open Sans", sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
}

i,
cite,
em,
var,
address,
dfn {
  font-style: italic;
}

.animationload {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background-color: #fff;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  width: 200px;
  height: 200px;
  background-image: url("/assets/images/cdc circle.png");
  background-position: center;
  background-repeat: no-repeat;
}

.pos-relative {
  position: relative;
}

.navbar-brand {
  padding: 0;
}

.section {
  position: relative;
}

.section>.container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section.overlap {
  /*transform: translateY(-125px);*/
  position: relative;
  z-index: 100;
  margin-top: -125px;
}

.section.overlap>.container {
  background-color: #fff;
  padding-top: 20px;
}

.section.overlap-bottom {
  position: relative;
  z-index: 100;
  margin-bottom: -125px;
}

.section.overlap-bottom>.container {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 0;
}

.section.section-border>.container {
  border-bottom: 1px solid #eee;
}

.section.banner {
  padding: 0 0;
}

.section.testimony>.container,
.section.page404>.container {
  padding-top: 50px;
  padding-bottom: 0;
}

.section.cta>.container {
  padding-top: 0;
  padding-bottom: 0;
}

.section.stat-client>.container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.bglight {
  background-color: #eff3f6;
}

.section-heading {
  font-size: 30px;
  line-height: 1.2em;
  letter-spacing: -.05em;
  font-weight: 800;
  color: #041e42;
  margin-bottom: 20px;
  border-top: 1px solid #dedede;
  padding-top: 20px;
  padding-bottom: 40px;
  position: relative;
}

.section-heading:before {
  content: '';
  border-top: 2px solid #ed1c24;
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
}

.section-heading.white {
  color: #fff;
  border-color: #627186;
}

.section-subheading {
  color: #999999;
}

.section-heading-2 {
  font-size: 24px;
  font-weight: 600;
  color: #041e42;
}

.section-heading-3 {
  font-size: 1.714em;
  line-height: normal;
}

.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.no-gutter>[class^="col-"],
.no-gutter>[class*=" col-"],
.no-gutter[class*='col-'],
.no-gutter[class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* 1.2. TEXT COLOR */
/* 1.3. ANCHOR */
a {
  color: #666666;
  text-decoration: none;
}

a:hover {
  color: #041e42;
  text-decoration: none;
}

/* 1.4. HEADING & PARAGRAPH */
p {
  margin: 0 0 20px 0;
}

h1,
h2,
.banner-page .title-page,
h3,
.jumbo-heading h2,
.jumbo-heading .banner-page .title-page,
.banner-page .jumbo-heading .title-page,
h4,
.lead,
h5,
.section-subheading {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
}

h1 {
  font-size: 3.571em;
  line-height: normal;
}

h2,
.banner-page .title-page {
  font-size: 2.857em;
  line-height: normal;
}

h3,
.jumbo-heading h2,
.jumbo-heading .banner-page .title-page,
.banner-page .jumbo-heading .title-page {
  font-size: 1.714em;
  line-height: normal;
}

h4,
.lead {
  font-size: 1.286em;
  line-height: normal;
}

h5,
.section-subheading {
  font-size: 14px;
  line-height: 22px;
}

h6 {
  font-size: 13px;
}

strong,
b {
  font-weight: 700;
}

.lead {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: #041e42;
  line-height: 1.6;
}

ol,
ul {
  list-style: initial;
  margin-left: 24px;
}

ol li,
ul li {
  margin: 8px 0;
}

.title-heading {
  color: #041e42;
  font-size: 36px;
  line-height: 50px;
}

blockquote {
  padding: 15px 20px;
  background-color: #f6f6f6;
  margin: 0 0 20px;
  border-left: 5px solid #46c2ca;
  font-style: italic;
}

.jumbo-heading {
  color: #041e42;
  font-size: 20px;
  position: relative;
}

.jumbo-heading h2,
.jumbo-heading .banner-page .title-page,
.banner-page .jumbo-heading .title-page {
  position: relative;
  z-index: 2;
}

.jumbo-heading span {
  font-size: 200px;
  position: absolute;
  top: -5px;
  left: 15px;
  color: #f5f5f5;
  z-index: 1;
}

ul.checklist {
  margin: 0;
}

ul.checklist li {
  list-style: none;
  margin: 10px 0 25px 35px;
}

ul.checklist li:before {
  font-family: FontAwesome;
  content: "\f00c";
  font-size: 20px;
  color: #041e42;
  margin-left: -35px;
  margin-right: 15px;
}

ul.bull {
  margin: 0 0 20px;
  list-style: none;
  padding: 0;
}

ul.bull li {
  position: relative;
  padding: 0 0 0 26px;
  margin: 0 0 14px;
  line-height: 18px;
}

ul.bull li:before {
  content: "\f105";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  left: 0;
  top: 2px;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  color: #46c2ca;
}

ul.circle {
  margin: 0 0 20px;
  list-style: none;
  padding: 0;
}

ul.circle li {
  position: relative;
  padding: 4px 0 4px 20px;
  margin: 0 0 14px;
  line-height: 22px;
}

ul.circle li:before {
  content: "\f10c";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  left: 0;
  top: 10px;
  display: inline-block;
  vertical-align: top;
  font-size: 7px;
  color: #46c2ca;
}

.bgsection {
  background: url("assets/images/1920x960_slider3.jpeg") bottom center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.bgsection:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #041e42;
  opacity: .9;
}

.grid-services {
  margin-top: 30px;
  margin-bottom: 30px;
}

.grid-item {
  margin-top: 30px;
  margin-bottom: 30px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}

.margin-bottom-90 {
  margin-bottom: 90px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.banner-page {
  height: 350px;
  display: block;
  background-color: #000;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.banner-page .title-page {
  text-align: left;
  color: #ffffff;
  margin-bottom: 0;
  text-shadow: 1px 2px 2px #7a7a7a;
}

.banner-page .breadcrumb {
  padding: 0;
  margin: 0;
  text-align: left;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
}

.banner-page .breadcrumb>.active,
.banner-page .breadcrumb>li+li:before,
.banner-page .breadcrumb a {
  color: #ffffff;
}

.banner-page .breadcrumb>.active:hover,
.banner-page .breadcrumb>li+li:before:hover,
.banner-page .breadcrumb a:hover {
  color: #46c2ca;
}

/* 2.5. CATEGORY MENU */
.widget {
  margin-bottom: 50px;
  clear: both;
}

.widget-title {
  font-size: 24px;
  font-weight: 700;
  color: #041e42;
  margin-bottom: 30px;
}

.category-nav {
  margin-left: 0;
  margin-bottom: 30px;
  padding: 0;
}

.category-nav {
  margin-left: 0;
  margin-bottom: 30px;
  padding: 0;
}

.category-nav li {
  list-style: none;
  margin: 0;
  font-weight: 700;
}

.category-nav li a {
  color: #666666;
  display: block;
  padding: 15px 20px;
  margin-bottom: 5px;
  background-color: #eff3f6;
  border-left: 5px solid transparent;
}

.category-nav li a:hover {
  border-color: #ed1c24;
}

.category-nav li.active a {
  border-color: #ed1c24;
}

.info-detail dl {
  font-size: 16px;
}

.info-detail dt {
  font-weight: 700;
}

.info-detail dd {
  margin-bottom: 20px;
}

.widget.tags a {
  display: inline-block;
  vertical-align: top;
  padding: 5px 8px 6px;
  font-size: 13px !important;
  color: rgba(34, 34, 34, 0.8);
  border: 1px solid #ccc;
  margin: 0 6px 7px 0;
  text-decoration: none !important;
  text-transform: lowercase;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.widget.tags a:hover {
  background-color: #ed1c24;
  color: #ffffff;
  border-color: #ed1c24;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #041e42;
}

/* ==========================
 Back To Top Section
 ============================= */
.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: #ed1c24 url("/assets/images/cd-top-arrow.svg") no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
}

.cd-top.cd-is-visible,
.cd-top.cd-fade-out,
.no-touch .cd-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
  visibility: visible;
  opacity: 1;
}

.cd-top.cd-fade-out {
  opacity: .5;
}

.cd-top:hover {
  opacity: 1;
}

/**
  * 3. - COMPONENTS
  * -----------------------------------------------------------------------------
  */
/* 2.1. PRELOADER */
.animationload {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background-color: #fff;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  width: 200px;
  height: 200px;
  background-image: url("/assets/images/cdc circle.png");
  background-position: center;
  background-repeat: no-repeat;
}

/* 2.2. BUTTONS */
:focus {
  outline: none;
}

.btn,
.btn-primary,
.btn-secondary {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #ffffff;
  padding: 15px 35px;
  border: 0;
  font-weight: 600;
  -webkit-border-radius: 3;
  -moz-border-radius: 3;
  -ms-border-radius: 3;
  border-radius: 3;
}

.btn-default {
  background-color: #ed1c24;
  color: #ffffff;
  padding: 15px 20px;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.btn-default:hover {
  background-color: #041e42;
}

.btn-cta {
  background-color: #ed1c24;
  color: #ffffff;
  padding: 15px 25px;
  border: 0;
  font-weight: 600;
  letter-spacing: 1px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.btn-cta:hover {
  background-color: #46c2ca;
  color: #ffffff;
}

.navbar-main .btn-orange-cta:hover {
  background-color: #041e42;
  color: #ffffff;
}

.btn-primary {
  background-color: #ed1c24;
}

.btn-primary:hover {
  background-color: #041e42;
  color: #ffffff;
}

.btn-secondary {
  background-color: #041e42;
}

.btn-secondary:hover {
  background-color: #ed1c24;
  color: #ffffff;
}

.btn-secondary.disabled {
  background-color: #041e42;
  border-color: #041e42;
}

.pagination {
  clear: both;
  text-align: left;
  margin-top: 20px;
}

.pagination>li {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}

.pagination>li>a {
  padding: 15px 20px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #666666;
  display: inline-block;
  border-color: transparent;
  background: #eee;
  border: 0;
}

.pagination>li>a:hover,
.pagination>li>a.active {
  background: #041e42;
  color: #fff;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination>.active>a {
  background: #041e42;
  color: #fff;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background: #041e42;
  color: #fff;
}

.btn-sidebar {
  border-radius: 0;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.btn-sidebar .fa {
  margin-right: 10px;
  font-size: 1.4em;
  vertical-align: middle;
}

/* 2.3. BOX ICON 1 */
.box-icon-1 {
  margin-bottom: 20px;
}

.box-icon-1 .icon {
  display: inline-block;
  color: #46c2ca;
  font-size: 36px;
  line-height: 43px;
  float: left;
}

.box-icon-1 .body-content {
  margin-left: 55px;
  color: #041e42;
  font-weight: 700;
}

.box-icon-1 .body-content .heading {
  color: #666666;
  font-weight: 400;
}

/* 2.4. BOX ICON 2 */
.box-icon-2 {
  margin-bottom: 40px;
  padding: 30px 30px 60px 30px;
  background-color: #eff3f6;
  border: 1px solid #dcdde1;
  min-height: 292px;
}

.box-icon-2 .icon {
  display: inline-block;
  color: #9a9a9a;
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 20px;
}

.box-icon-2 .body-content {
  color: #222222;
}

.box-icon-2 .body-content .heading {
  color: #041e42;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
}

.box-icon-2:hover {
  background-color: #46c2ca;
}

.box-icon-2:hover .icon,
.box-icon-2:hover .body-content {
  color: #ffffff;
}

/* 2.4. BOX ICON 3 */
.box-icon-3 {
  padding: 50px 30px 60px 30px;
  border: 1px solid #dcdde1;
  text-align: center;
  position: relative;
  background-color: #eff3f6;
  min-height: 300px;
}

.box-icon-3 .icon {
  display: inline-block;
  color: #041e42;
  font-size: 50px;
  line-height: 43px;
  margin-bottom: 40px;
}

.box-icon-3 .body-content {
  color: #222222;
}

.box-icon-3 .body-content .heading {
  color: #041e42;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .2px;
}

.box-icon-3 .body-content .readmore {
  display: block;
  margin-top: 20px;
  font-weight: 700;
  -webkit-transform: translate3d(0px, 25px, 0px);
  transform: translate3d(0px, 25px, 0px);
  -webkit-transition: all 0.4s ease 0s !important;
  transition: all 0.4s ease 0s !important;
  opacity: 0;
  color: #041e42;
}

.box-icon-3:hover {
  background-color: #46c2ca;
  -webkit-box-shadow: 0 10px 18px #eee;
  -moz-box-shadow: 0 10px 18px #eee;
  box-shadow: 0 10px 18px #eee;
}

.box-icon-3:hover .icon {
  color: #ffffff;
}

.box-icon-3:hover .body-content {
  color: #ffffff;
}

.box-icon-3:hover .body-content .readmore {
  opacity: 1;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.box-icon-3 .line-t {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.box-icon-3 .line-t:before {
  content: '';
  width: 2px;
  height: 2px;
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  background-color: #041e42;
}

.box-icon-3 .line-t:after {
  content: '';
  width: 2px;
  height: 2px;
  display: block;
  position: absolute;
  top: -1px;
  right: -1px;
  background-color: #041e42;
}

.box-icon-3 .line-b {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.box-icon-3 .line-b:before {
  content: '';
  width: 2px;
  height: 2px;
  display: block;
  position: absolute;
  bottom: -1px;
  left: -1px;
  background-color: #041e42;
}

.box-icon-3 .line-b:after {
  content: '';
  width: 2px;
  height: 2px;
  display: block;
  position: absolute;
  bottom: -1px;
  right: -1px;
  background-color: #041e42;
}

/* 2.5. BOX ICON 4 */
.box-icon-4 {
  margin-bottom: 20px;
  padding: 30px 15px;
  background-color: #eff3f6;
  border: 1px solid #dcdde1;
  text-align: center;
  height: 145px;
  position: relative;
  overflow: hidden;
}

.box-icon-4 .icon {
  display: inline-block;
  color: rgba(255, 255, 255, 0.2);
  font-size: 100px;
  position: absolute;
  right: -40px;
  bottom: -20px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.box-icon-4 .body-content {
  color: #222222;
}

.box-icon-4 .body-content .heading {
  color: #041e42;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
}

.box-icon-4:hover {
  background-color: #46c2ca;
}

.box-icon-4:hover .icon {
  right: 0;
}

.box-icon-4:hover .body-content {
  color: #ffffff;
}

/* 2.5. BOX IMAGE 1 */
.box-image-1 {
  position: relative;
  margin-bottom: 30px;
}

.box-image-1 .image {
  overflow: hidden;
  background-color: #000;
}

.box-image-1 .image img {
  opacity: .9;
  max-width: 100%;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition: 0.1s all linear;
  transition: 0.1s all linear;
}

.box-image-1:hover .image img {
  opacity: .4;
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  box-shadow: none;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.box-image-1 .description {
  position: absolute;
  left: 40px;
  bottom: 40px;
}

.box-image-1 .blok-title {
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
}

/* 2.6. BOX IMAGE 2 */
.box-image-2 .image img {
  width: 100%;
}

.box-image-2 .blok-title {
  color: #041e42;
  font-size: 24px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 20px;
}

/* 2.7. BOX IMAGE 4 */
.box-image-4 {
  position: relative;
  margin-bottom: 30px;
}

.box-image-4 .media {
  overflow: hidden;
  background-color: #041e42;
  position: relative;
}

.box-image-4 .media img {
  opacity: 1;
  max-width: 100%;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition: 0.1s all linear;
  transition: 0.1s all linear;
}

.box-image-4 .body {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: -webkit-calc(100% - 20px);
  height: calc(100% - 20px);
  width: -webkit-calc(100% - 20px);
  width: calc(100% - 20px);
  background-color: rgba(4, 30, 66, 0.4);
  border: solid 10px #46c2ca;
  opacity: 0;
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out;
}

.box-image-4 .body .content {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.box-image-4 .title {
  color: #ffffff;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.box-image-4 .category {
  color: #ffffff;
}

.box-image-4:hover .media img {
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  box-shadow: none;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.box-image-4:hover .body {
  opacity: 1;
}

.box-image-4:hover .title {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/* 2.7. BOX IMAGE 3 */
.box-image-3 {
  position: relative;
  margin-bottom: 40px;
}

.box-image-3 .image {
  overflow: hidden;
  background-color: #041e42;
  position: relative;
}

.box-image-3 .image:before {
  content: '';
  position: absolute;
  background: url("/assets/images/link.gif") center top no-repeat;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  text-align: center;
  z-index: 9;
  color: #ffffff;
  font-size: 30px;
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all ease .25s;
  transition: all ease .25s;
}

.box-image-3 .image img {
  opacity: 1;
  max-width: 100%;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition: 0.1s all linear;
  transition: 0.1s all linear;
}

.box-image-3:hover .image img {
  opacity: .2;
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.box-image-3:hover .image:before {
  top: 50%;
  filter: alpha(opacity=1);
  opacity: 100;
}

.box-image-3 .blok-title {
  text-align: center;
  color: #666666;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.box-image-3:hover .blok-title {
  color: #041e42;
}

/* 2.8. BOX IMAGE 4 */
.feature-box-8 {
  position: relative;
  background-color: #041e42;
  color: #ffffff;
  margin-bottom: 30px;
}

// first 3 elements
.col-sm-6:nth-of-type(-n+3)>.feature-box-8 {
  min-height: 521px !important;
}

// last 3 elements
.col-sm-6:nth-last-of-type(-n+3)>.feature-box-8 {
  min-height: 500px !important;
}

.feature-box-8 .media {
  width: 100%;
  margin-bottom: 30px;
}

.feature-box-8 .body {
  position: relative;
  text-align: center;
  padding: 30px 40px 30px;
}

.feature-box-8 .body .icon-holder {
  position: absolute;
  left: 50%;
  top: -30px;
  width: 84px;
  height: 84px;
  line-height: 100px;
  background-color: #041e42;
  color: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.feature-box-8 .body .icon {
  font-size: 40px;
}

.feature-box-8 .body .title {
  color: #46c2ca;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .2px;
  display: inline-block;
}

.feature-box-8 .body .readmore {
  display: block;
  margin-top: 20px;
  font-weight: 700;
  -webkit-transform: translate3d(0px, 25px, 0px);
  transform: translate3d(0px, 25px, 0px);
  -webkit-transition: all 0.4s ease 0s !important;
  transition: all 0.4s ease 0s !important;
  opacity: 0;
  color: #041e42;
}

.feature-box-8:hover {
  background-color: #46c2ca;
  color: #000;
}

.feature-box-8:hover .icon-holder {
  background-color: #46c2ca;
}

.feature-box-8:hover .title {
  color: #041e42;
}

.feature-box-8:hover .readmore {
  opacity: 1;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/* 2.8. BOX TESTIMONY */
.box-testimony {
  position: relative;
  box-sizing: border-box;
}

.box-testimony:before {
  content: "\f10e";
  color: #ed1c24;
  font-family: FontAwesome;
  font-size: 60px;
  float: left;
  line-height: initial;
}

.box-testimony .quote-box {
  margin-left: 80px;
}

.box-testimony .quote-box blockquote {
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  padding: 0;
  background-color: transparent;
  margin: 0;
  border-left: 0;
  font-style: italic;
}

.box-testimony .quote-box .quote-name {
  margin-top: 30px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #041e42;
}

.box-testimony .quote-box .quote-name span {
  font-size: 12px;
  font-style: italic;
  color: #222222;
  font-weight: normal;
  display: block;
}

/* TESTIMONIALS 1 */
.testimonial-1 {
  position: relative;
}

.testimonial-1 .media {
  float: left;
  margin: 0 30px 0 0;
  max-width: 255px;
  display: block;
  position: relative;
  background-color: #041e42;
}

.testimonial-1 .media img {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition: 0.4s all linear;
  transition: 0.4s all linear;
}

.testimonial-1:hover .media img {
  filter: alpha(opacity=30);
  opacity: .3;
  -webkit-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.testimonial-1 .body {
  overflow: hidden;
  color: #222222;
}

.testimonial-1 .title {
  color: #041e42;
  font-weight: 700;
  font-size: 16px;
}

.testimonial-1 .position {
  color: #a2a2a2;
}

.testimonial-1 .company {
  color: #a2a2a2;
  margin-bottom: 20px;
}

/* TESTIMONIALS 2 */
.testimonial-2 {
  position: relative;
  margin-bottom: 30px;
  margin-left: 0;
  margin-bottom: 100px;
  background-color: #041e42;
}

.testimonial-2 .body {
  border: none;
  margin-bottom: 0;
  padding: 30px;
  font-weight: 500;
  color: #fff;
  min-height: 140px;
}

.testimonial-2 .info {
  background-color: #fff;
  display: inline-block;
  padding: 20px 20px;
  margin-top: -40px;
  float: right;
  min-width: 290px;
  border-bottom: 2px solid #041e42;
  height: 90px;
  position: relative;
  z-index: 3;
}

.testimonial-2 .info .media {
  max-width: 60px;
  max-height: 60px;
  float: left;
}

.testimonial-2 .info .info-title {
  margin-left: 80px;
}

.testimonial-2 .info .title {
  color: #041e42;
  font-weight: 700;
  font-size: 16px;
}

.testimonial-2 .info .position {
  color: #a2a2a2;
}

.testimonial-2 .info .company {
  color: #a2a2a2;
  margin-bottom: 20px;
}

.testimonial-2:hover {
  background-color: #46c2ca;
}

.testimonial-2:hover .info {
  border-color: #46c2ca;
}

/* TESTIMONIALS 3 */
.testimonial-3 .media {
  text-align: center;
  float: left;
}

.testimonial-3 .media img {
  width: 160px;
  margin-bottom: 25px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}

.testimonial-3 .body {
  padding-left: 200px;
  font-size: 2.7rem;
  line-height: normal;
}

.testimonial-3 .title {
  color: #000;
  font-weight: 700;
  font-size: 18px;
}

.testimonial-3 .company {
  color: #777;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .testimonial-3 .media {
    float: none;
  }

  .testimonial-3 .media img {
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .testimonial-3 .body {
    padding-left: 0;
    text-align: center;
  }
}

/* 2.9. BOX TEAM 2 */
.box-team {
  border: 1px solid #eee;
  margin-bottom: 30px;
  background-color: #041e42;
  color: #fff;
}

.box-team:hover {
  background-color: #041e42;
}

.box-team:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}

.box-team .box-image {
  float: left;
  width: 50%;
  position: relative;
}

.box-team .box-image img {
  display: block;
  width: 100%;
}

.box-team .box-image .sosmed {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 10px 0;
}

.box-team .box-image .sosmed .fa {
  font-size: 18px;
  color: #041e42;
  padding: 0 20px;
}

.box-team .box-image .sosmed .fa:hover {
  color: #46c2ca;
}

.box-team .body-content {
  float: left;
  width: 50%;
  padding: 30px 30px;
  position: relative;
}

.box-team .body-content .people {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.box-team .body-content .position {
  color: #46c2ca;
  font-size: 12px;
  font-style: italic;
}

.box-team .body-content .excert {
  margin-top: 20px;
  margin-bottom: 35px;
}

.box-team .body-content .phone {
  font-size: 18px;
}

.box-team .body-content .phone .fa {
  font-size: 30px;
  color: #ed1c24;
  margin-right: 10px;
  vertical-align: middle;
}

.box-team:hover {
  background-color: #46c2ca;
  color: #000;
}

.box-team:hover .position {
  color: #041e42;
}

/* 2.10. BOX STATISTIC */
.box-statistic {
  text-align: center;
  margin-top: 40px;
}

.box-statistic .icon {
  font-size: 36px;
  color: #ed1c24;
  margin-bottom: 30px;
}

.box-statistic .heading {
  font-size: 36px;
  color: #041e42;
  font-weight: 700;
  margin-bottom: 20px;
}

.box-statistic .subheading {
  font-size: 14px;
  font-weight: 600;
  color: #46c2ca;
}

.counter-1 .counter-number {
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0;
  width: 70px;
  padding-right: 14px;
  letter-spacing: -.5px;
  font-weight: 700;
  font-size: 48px;
  color: #46c2ca;
}

.counter-1 .counter-number-2 {
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0;
  padding-right: 14px;
  letter-spacing: -.5px;
  font-weight: 700;
  font-size: 48px;
  color: #46c2ca;
}

.counter-1 .counter-title {
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0;
  text-align: left;
  line-height: 18px;
  margin-bottom: 0;
  color: #ffffff;
}

/* 2.11. BOX HISTORY */
.box-history .item {
  padding-bottom: 40px;
}

.box-history .item:before {
  content: '';
  position: absolute;
  left: 92px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #eee;
  z-index: -1;
}

.box-history .item .year {
  float: left;
  font-size: 18px;
  font-weight: 700;
}

.box-history .item .year-info {
  position: relative;
  margin-left: 100px;
}

.box-history .item .year-info:before {
  content: '';
  position: absolute;
  left: -30px;
  width: 15px;
  height: 15px;
  background-color: #ed1c24;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

.box-history .item .year-info .heading {
  font-size: 24px;
  color: #041e42;
  margin-bottom: 20px;
}

.box-history .item:after {
  content: '';
  clear: both;
  display: block;
}

.history-2 .timeline__item .media {
  display: table-cell;
  width: 30%;
}

.history-2 .timeline__item .media img {
  margin-bottom: 80px;
}

.history-2 .timeline__item .year {
  font-size: 6rem;
  font-weight: 700;
  color: #002e5b;
  letter-spacing: -.04em;
  margin-top: -15px;
  line-height: normal;
}

.history-2 .timeline__item .aksen {
  position: relative;
  display: table-cell;
  width: 80px;
}

.history-2 .timeline__item .aksen:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 20px;
  bottom: -5px;
  width: 1px;
  background: #e6e6e6;
  border-radius: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.history-2 .timeline__item .aksen:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 8px;
  width: 9px;
  height: 9px;
  background: #fde428;
  border-radius: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.history-2 .timeline__item:last-child .aksen:before {
  display: none;
}

.history-2 .timeline__item .text {
  display: table-cell;
  padding: 0 0 50px;
  vertical-align: top;
}

.history-2 .timeline__item .text .title {
  font-size: 20px;
  margin: 0 0 16px;
  padding: 0;
  font-weight: 700;
}

@media (max-width: 767px) {
  .history-2 .timeline__item .media {
    display: block;
    width: 100%;
  }

  .history-2 .timeline__item .media img {
    margin-bottom: 20px;
  }

  .history-2 .timeline__item .aksen {
    display: none;
  }
}

/* 2.12. BOX PARTNERS */
.box-partner {
  margin-top: 30px;
  margin-bottom: 30px;
}

.box-partner .item {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.box-partner .item .box-image {
  float: left;
  width: 20%;
  font-size: 18px;
}

.box-partner .item .box-image img {
  width: 100%;
  border: 1px solid #eee;
  padding: 20px;
}

.box-partner .item .box-info {
  float: left;
  width: 70%;
  position: relative;
  margin-left: 40px;
}

.box-partner .item .box-info .heading {
  font-size: 24px;
  color: #041e42;
  font-weight: 700;
  margin-bottom: 20px;
}

.box-partner .item:after {
  content: '';
  clear: both;
  display: block;
}

.box-partner .item:last-child {
  border-bottom: 0;
}

/* 2.13. PANEL ACCORDION */
.panel-faq.panel-group .panel {
  border-radius: 0px;
}

.panel-faq .panel-default {
  border-color: transparent;
}

.panel-faq .panel-default>.panel-heading {
  color: #ffffff;
  background-color: #041e42;
  border-color: #ddd;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 13px 20px;
  position: relative;
}

.panel-faq .panel-default>.panel-heading:before {
  padding: 0 0px 0 20px;
  content: "\002B";
  font-family: Arial;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 20px;
}

.panel-faq .panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: transparent;
}

.panel-faq .panel-default.active>.panel-heading {
  background-color: #eff3f6;
  color: #041e42;
}

.panel-faq .panel-default.active>.panel-heading:before {
  color: #041e42;
  content: "\002D";
}

.panel-faq .panel-default:hover>.panel-heading {
  color: #46c2ca;
}

.panel-faq .panel-default:hover>.panel-heading.active {
  color: #041e42;
}

.panel-faq .panel-title {
  font-size: 16px;
  display: block;
}

.panel-faq .panel-title>a,
.panel-faq .panel-title>a:active,
.panel-faq .panel-title>a:focus {
  color: inherit;
  text-decoration: none;
  display: block;
}

.panel-faq .panel-body {
  padding: 15px 20px;
  background-color: #eff3f6;
  margin-top: 0;
}

.panel-faq .panel-body p {
  margin: 10px 0;
}

.panel-faq .collapse.in {
  display: block;
  visibility: visible;
}

/* 2.14. FAQ */
.faq-list {
  margin: 0;
  padding: 0;
}

.faq-list li {
  list-style: none;
}

.faq-list li .fa {
  color: #f00;
  font-size: 14px;
  margin-right: 10px;
}

.faq-list .faq-item-image {
  position: relative;
  width: 100%;
  text-align: center;
}

.faq-list .faq-item-image img {
  display: block;
}

.faq-1 .item {
  margin-bottom: 70px;
}

.faq-1 .item .question {
  position: relative;
  padding-left: 40px;
  font-size: 1.714em;
  line-height: normal;
  margin-bottom: 30px;
}

.faq-1 .item .question:before {
  content: "\f059";
  color: #ed1c24;
  font-family: FontAwesome;
  position: absolute;
  left: 0;
  top: 5px;
}

.faq-1 .item .answer {
  position: relative;
  padding-left: 40px;
  margin-bottom: 30px;
}

.faq-1 .item .answer:before {
  content: "\f064";
  color: #46c2ca;
  font-family: FontAwesome;
  position: absolute;
  left: 0;
  top: 5px;
}

/* 2.15. PRICING TABLE */
.panel-pricing {
  border-color: #ccc;
  text-align: center;
  margin-bottom: 40px;
  background-color: #f6f6f6;
  border-radius: 0;
}

.panel-pricing.best .price {
  background-color: #46c2ca;
}

.panel-pricing .price {
  padding: 15px 0;
  font-size: 80px;
  background-color: #041e42;
  font-size: 36px;
  color: #fff;
  font-weight: 700;
}

.panel-pricing .price sup {
  font-size: 18px;
  margin-right: 5px;
  top: -.6em;
  font-weight: 400;
}

.panel-pricing .price small {
  font-size: 14px;
  margin-left: 0px;
}

.panel-pricing h3,
.panel-pricing .jumbo-heading h2,
.jumbo-heading .panel-pricing h2,
.panel-pricing .jumbo-heading .banner-page .title-page,
.jumbo-heading .banner-page .panel-pricing .title-page,
.panel-pricing .banner-page .jumbo-heading .title-page,
.banner-page .jumbo-heading .panel-pricing .title-page {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-top: 10px;
}

.panel-pricing>.panel-heading {
  color: #666666;
  border-color: #eee;
  padding: 0px 0px;
}

.panel-pricing .table>tbody>tr>td,
.panel-pricing .table>tfoot>tr>td {
  padding: 12px 0;
  vertical-align: top;
  border-top: none;
}

.panel-pricing .table {
  margin-bottom: 0;
}

.panel-pricing .panel-body {
  padding: 10px;
  text-align: center;
}

.panel-pricing .panel-body table,
.panel-pricing .panel-body th,
.panel-pricing .panel-body td {
  border: 0;
  text-align: center;
}

.panel-pricing .panel-footer {
  padding: 30px 0;
  border-top: 0;
}

/* 2.16. CAREER TABS */
.career-tabs>ul {
  margin-left: 0px;
}

.career-tabs .nav-tabs {
  border-bottom: 0;
}

.career-tabs .nav-tabs>li {
  float: left;
  margin-bottom: 0;
  width: 25%;
  background: #041e42;
  text-align: center;
}

.career-tabs .nav-tabs>li>a {
  padding: 20px 0;
  color: #ffffff;
  margin-right: 0;
  border-radius: 0;
  font-weight: 700;
}

.career-tabs .nav-tabs>li>a:hover {
  background-color: #46c2ca;
}

.career-tabs .nav-tabs>li.active>a,
.career-tabs .nav-tabs>li.active>a:hover,
.career-tabs .nav-tabs>li.active>a:focus {
  color: #041e42;
  cursor: default;
  background-color: #eff3f6;
}

.career-tabs .tab-content {
  padding: 20px 30px;
  border: 1px solid #eee;
  background-color: #eff3f6;
}

/* 3.17. BOX NEWS */
.box-news-1 {
  position: relative;
  margin-bottom: 30px;
}

.box-news-1 .media {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #041e42;
}

.box-news-1 .media img {
  -webkit-transition: 0.4s all linear;
  transition: 0.4s all linear;
}

.box-news-1 .media:before {
  content: "\f0c1";
  font-family: FontAwesome;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  text-align: center;
  z-index: 9;
  color: #ffffff;
  font-size: 30px;
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all ease .25s;
  transition: all ease .25s;
}

.box-news-1 .body {
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.box-news-1 .body:before {
  background-color: #ed1c24;
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(0, 1);
  -moz-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  -o-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
  -moz-transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
  -o-transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
  transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
}

.box-news-1 .body .title {
  font-weight: 700;
}

.box-news-1 .body .title a {
  color: #041e42;
}

.box-news-1 .body .title a:hover {
  color: #ed1c24;
}

.box-news-1 .body .meta {
  color: #6f6f6f;
  font-size: 13px;
  margin-top: 5px;
}

.box-news-1 .body .meta span {
  display: inline-table;
  margin-right: 20px;
}

.box-news-1 .body .meta .fa {
  color: #46c2ca;
}

.box-news-1:hover .body:before {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.box-news-1:hover .media img {
  filter: alpha(opacity=80);
  opacity: .4;
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.box-news-1:hover .media:before {
  top: 50%;
  filter: alpha(opacity=1);
  opacity: 100;
}

/* 3.17. BOX NEWS */
.box-news-2 {
  position: relative;
  margin-bottom: 40px;
}

.box-news-2 .image {
  overflow: hidden;
  background-color: #041e42;
  position: relative;
}

.box-news-2 .image img {
  opacity: 1;
  max-width: 100%;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition: 0.1s all linear;
  transition: 0.1s all linear;
}

.box-news-2 .image:before {
  content: '';
  position: absolute;
  background: url("/assets/images/link.gif") center top no-repeat;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  text-align: center;
  z-index: 9;
  color: #ffffff;
  font-size: 30px;
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all ease .25s;
  transition: all ease .25s;
}

.box-news-2:hover .image img {
  opacity: .2;
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.box-news-2:hover .image:before {
  top: 50%;
  filter: alpha(opacity=1);
  opacity: 100;
}

.box-news-2 .meta-date {
  margin-top: 10px;
  color: #041e42;
  font-size: 12px;
  font-style: italic;
}

.box-news-2 .blok-title {
  color: #666666;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 20px;
  height: 50px;
  overflow: hidden;
}

.box-news-2 .blok-title a:hover {
  color: #ed1c24;
}

/* 2.18. LIST NEWS */
.meta-blog,
.list-news .news-item .meta,
.single-news .meta {
  margin-bottom: 20px;
}

.meta-blog:after,
.list-news .news-item .meta:after,
.single-news .meta:after {
  clear: both;
  content: " ";
  display: table;
}

.meta-blog>div,
.list-news .news-item .meta>div,
.single-news .meta>div {
  float: left;
  font-size: 13px;
  line-height: normal;
}

.meta-blog>div:last-child,
.list-news .news-item .meta>div:last-child,
.single-news .meta>div:last-child {
  text-align: right;
  float: right;
}

.meta-blog>div i,
.list-news .news-item .meta>div i,
.single-news .meta>div i {
  color: #46c2ca;
}

.meta-blog .meta-author,
.list-news .news-item .meta .meta-author,
.single-news .meta .meta-author,
.meta-blog .meta-category,
.list-news .news-item .meta .meta-category,
.single-news .meta .meta-category {
  margin: 0 0 10px 20px;
  padding: 0 0 0 21px;
}

@media (max-width: 768px) {

  .meta-blog>div,
  .list-news .news-item .meta>div,
  .single-news .meta>div {
    display: block;
    float: none;
    margin-bottom: 10px;
  }

  .meta-blog>div:last-child,
  .list-news .news-item .meta>div:last-child,
  .single-news .meta>div:last-child {
    text-align: left;
    float: none;
  }

  .meta-blog .meta-author,
  .list-news .news-item .meta .meta-author,
  .single-news .meta .meta-author,
  .meta-blog .meta-category,
  .list-news .news-item .meta .meta-category,
  .single-news .meta .meta-category {
    margin-left: 0;
    padding-left: 0;
  }
}

.list-news {
  width: 98%;
}

.list-news .news-item {
  margin-bottom: 70px;
  padding-right: 15px;
  padding-left: 15px;
  /*.meta-date{
          margin-top: 10px;
          color: $blue_1;
          font-size: 12px;
          font-style: italic;
     }
     .meta-author{
       color: $blue_1;
       font-style: italic;
       font-size: 12px;
       margin-bottom: 20px;
     }*/
}

.list-news .news-item .image {
  position: relative;
  overflow: hidden;
  background-color: #041e42;
}

.list-news .news-item .image:before {
  content: "\f0c1";
  font-family: FontAwesome;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  text-align: center;
  z-index: 9;
  color: #ffffff;
  font-size: 30px;
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all ease .25s;
  transition: all ease .25s;
}

.list-news .news-item .image img {
  opacity: 1;
  max-width: 100%;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition: 0.1s all linear;
  transition: 0.1s all linear;
}

.list-news .news-item .image .meta-date {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 70px;
  height: 70px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: #ed1c24;
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding-top: 13px;
  z-index: 1;
}

.list-news .news-item .image .meta-date span {
  font-size: 14px;
  display: block;
}

.list-news .news-item:hover .image img {
  opacity: .2;
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.list-news .news-item:hover .image:before {
  top: 50%;
  filter: alpha(opacity=1);
  opacity: 100;
}

.list-news .news-item .blok-title {
  font-size: 24px;
  margin-top: 5px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 700;
}

.list-news .news-item .blok-title a {
  color: #041e42;
}

.list-news .news-item .blok-title a:hover {
  color: #ed1c24;
}

@media (max-width: 1024px) {
  .list-news {
    width: initial;
  }
}

/**
  * 4. - LOGO & NAVIGATION
  * -----------------------------------------------------------------------------
  */
/* 4.1 - TOPBAR */
.topbar {
  background-color: #041e42;
  padding: 0 0;
  height: 40px;
}

.topbar .topbar-left .welcome-text {
  font-style: italic;
  color: #ffffff;
  padding: 10px 0;
  font-size: 1.2rem;
}

.topbar .topbar-right {
  text-align: right;
}

.topbar .topbar-right .topbar-menu {
  display: inline-table;
  margin-bottom: 0;
  vertical-align: top;
}

.topbar .topbar-right .topbar-menu li {
  list-style: none;
  float: left;
  color: #ffffff;
  padding: 0 10px;
  font-size: 12px;
}

.topbar .topbar-right .topbar-menu li a {
  color: #ffffff;
}

.topbar .topbar-right .topbar-menu li a:hover {
  color: #46c2ca;
}

.topbar .topbar-right .topbar-sosmed {
  display: inline-table;
  margin-bottom: 0;
}

.topbar .topbar-right .topbar-sosmed li {
  list-style: none;
  float: left;
  font-size: 16px;
  padding: 0 10px;
}

.topbar .topbar-right .topbar-sosmed li a {
  color: #ffffff;
}

.topbar .topbar-right .topbar-sosmed li a:hover {
  color: #46c2ca;
}

.topbar-logo {
  background-color: #ffffff;
  padding: 5px 0;
}

.contact-info {
  float: right;
  margin-top: 15px;
}

.contact-info>div {
  display: inline-table;
  margin-right: 40px;
}

.navbar-toggle .icon-bar {
  background: #041e42;
}

.navbar-toggle {
  float: left;
}

/* 4.2 - NAVBAR */
.rowe {
  background-color: #ffffff;
  border-top: 1px solid #ccc;
}

.navbar-main {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;
  border: 0;
  position: relative;
  z-index: 103;
  bottom: -22px;
  -webkit-transition: padding .2s ease-in-out;
  transition: padding .2s ease-in-out;
  /*@include translateY(22px);*/
}

.navbar-main>.container {
  position: relative;
}

.navbar-main.stiky {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding-top: 40px;
  bottom: auto;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  bottom: initial;
}

.navbar-main .navbar-brand {
  position: absolute;
  left: 40px;
  top: -85px;
}

.navbar-main .navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.navbar-main ol,
.navbar-main li {
  margin: 0 0;
  font-size: 12px;
  font-weight: 700;
}

.navbar-main .nav>li>a {
  color: #222222;
}

.navbar-main .nav>li.active>a,
.navbar-main .nav>li>a:hover,
.navbar-main .nav>li>a:focus {
  color: #46c2ca;
  background-color: transparent;
}

.navbar-main .nav .open>a,
.navbar-main .nav .open>a:hover,
.navbar-main .nav .open>a:focus {
  background-color: transparent;
  border-color: transparent;
  color: #ed1c24;
}

.navbar-main .caret {
  margin-left: 10px;
}

.navbar-main .nav.navbar-right>li {
  margin-right: 15px;
}

.navbar-main .fa {
  font-size: 18px;
}

.navbar-main .dropdown-menu {
  background-color: #041e42;
  min-width: 180px;
  color: #ffffff;
  padding: 0;
  border: 0;
  border-radius: 0;
}

.navbar-main .dropdown-menu>li {
  text-transform: uppercase;
  border-bottom: 1px solid #073472;
}

.navbar-main .dropdown-menu>li a {
  padding: 10px 20px;
  color: #ffffff;
}

.navbar-main .dropdown-menu>li a:hover {
  background-color: #46c2ca;
  color: #041e42;
}

/**
  * 5. - HOME
  * -----------------------------------------------------------------------------
  */
/* 5.1 - HERO */
.banner {
  position: relative;
  padding: 0 0;
}

.banner .overlay-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.banner ol li,
.banner ul li {
  margin: 0;
}

.banner .btn-primary:hover,
.banner .btn-secondary:hover {
  background-color: #46c2ca;
}

.slides-navigation {
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  bottom: 46%;
  width: 100%;
}

.slides-navigation.center {
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  bottom: 25%;
  width: 100%;
  text-align: center;
  -webkit-transform: translateX(-7px);
  -ms-transform: translateX(-7px);
  transform: translateX(-7px);
}

.slides-navigation .container {
  position: relative;
}

.slides-navigation a {
  position: absolute;
  display: block;
}

.slides-navigation.center a {
  position: relative;
  display: inline-table;
  text-align: center;
}

/*.slides-navigation {
   bottom: 20%;
 }*/
.slides-navigation a {
  color: #ffffff;
  font-size: 14px;
  margin: 5px;
  background: transparent;
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  width: 40px;
  height: 40px;
  border: 3px solid #ffffff;
  padding-top: 7px;
  text-align: center;
}

.slides-navigation a:hover {
  color: #46c2ca;
  border-color: #46c2ca;
  text-decoration: none;
}

.slides-navigation a.prev {
  left: -60px;
}

.slides-navigation a.next {
  right: -60px;
}

.slides-navigation.center a.next {
  left: 0;
  right: 0;
}

.slides-navigation.center a.prev {
  left: 0;
}

/* 5.2 - Text Typing */
.wrap-caption {
  padding-top: 6%;
  color: #ffffff;
  width: 50%;
}

.wrap-caption.center {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.wrap-caption .excerpt {
  margin-bottom: 50px;
  // font-size: 26px;
  font-size: large;
  line-height: 3.5rem;
  text-shadow: 1px 1px 2px #1a1a1a;
  text-align: left;
}

.wrap-caption .btn,
.wrap-caption .btn-primary,
.wrap-caption .btn-secondary {
  margin-right: 15px;
}

/**
  * 6. - SECTION
  * -----------------------------------------------------------------------------
  */
/* 6.1 - About */
.banner-page.about {
  background: url("/assets/images/1920x960.jpg") bottom center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.director-image {
  position: relative;
  margin-bottom: 60px;
}

.director-image .director-image-title {
  background-color: #46c2ca;
  letter-spacing: -.6px;
  color: #fff;
  text-align: right;
  padding: 10px 30px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.director-image:after {
  position: absolute;
  height: 40px;
  background-color: #46c2ca;
  left: 0;
  bottom: -40px;
  right: 40px;
}

.director-title {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 0;
}

.director-position {
  font-size: 14px;
  color: #041e42;
  text-transform: uppercase;
  font-weight: 600;
}

.bos-quote {
  padding: 20px;
  background-color: #ffffff;
  border: 4px solid #dcdde1;
}

.bos-quote .bos-photo {
  float: left;
  width: 100px;
}

.bos-quote .bos-photo img {
  display: block;
}

.bos-quote .bos-text {
  margin-left: 120px;
}

.bos-quote:after {
  content: '';
  clear: both;
  display: inline-table;
}

.box-vision {
  border-left: 10px solid #041e42;
  padding: 15px 30px;
  font-size: 20px;
  line-height: normal;
  background-color: #eff3f6;
  color: #041e42;
  margin-bottom: 60px;
  font-weight: 400;
  font-style: italic;
}

dl.hiw {
  width: 100%;
}

dl.hiw dt {
  width: 30px;
  float: left;
}

dl.hiw dt .fa {
  font-size: 24px;
  color: #041e42;
}

dl.hiw dd {
  margin-left: 60px;
  margin-bottom: 40px;
}

dl.hiw dd h4,
dl.hiw dd .lead {
  clear: none;
}

.vidimg {
  position: relative;
}

.vidimg .play-vid {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 72px;
  height: 72px;
  background-color: #041e42;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: block;
  border: 2px solid #ffffff;
  padding-top: 13px;
  padding-left: 7px;
  text-align: center;
}

.vidimg .play-vid a {
  color: #ffffff;
}

.vidimg .play-vid:hover {
  background-color: #ed1c24;
}

/* 6.2 - Services */
.banner-page.project {
  background: url("/assets/images/1920x960.jpg") bottom center no-repeat;
}

.section.project {
  background: url("/assets/images/1920x960.jpg") bottom center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.section.project:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #041e42;
  opacity: .9;
}

.portfolio_filter {
  margin: 0;
  padding: 0;
}

.portfolio_filter li {
  float: left;
  list-style: none;
  padding: 0 10px;
  text-transform: uppercase;
  text-decoration: none;
}

.portfolio_filter li a {
  color: #fff;
  font-weight: 700;
}

.portfolio_filter li a.active,
.portfolio_filter li a:hover {
  color: #ed1c24;
  text-decoration: none;
}

.portfolio_filter.dark li a {
  color: #666666;
}

.portfolio_filter.dark li a.active,
.portfolio_filter.dark li a:hover {
  color: #ed1c24;
  text-decoration: none;
}

/* 6.3 - Pages */
.banner-page.pages {
  background: url("/assets/images/slide2-2.jpg") bottom center no-repeat;
}

/* 6.3 - Projects */
.banner-page.project {
  background: url("/assets/images/slide2-2.jpg") bottom center no-repeat;
}

/* 6.4 - News */
.banner-page.news {
  background: url("/assets/images/slide2-2.jpg") bottom center no-repeat;
}

.single-news .image {
  position: relative;
}

.single-news .image .meta-date {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 70px;
  height: 70px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: #ed1c24;
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding-top: 13px;
}

.single-news .image .meta-date span {
  font-size: 14px;
  display: block;
}

.single-news .blok-title {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #041e42;
}

.author-box {
  background-color: #f6f6f6;
  padding: 20px;
  margin-top: 60px;
}

.author-box .media {
  display: table-cell;
  vertical-align: top;
  width: 120px;
}

.author-box .media img {
  width: 100px;
  height: 100px;
}

.author-box .body {
  margin-left: 20px;
  display: table-cell;
  vertical-align: top;
}

.author-box .media-heading {
  color: #222222;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.author-box .media-heading span {
  font-weight: 400;
  font-size: 12px;
  display: block;
  color: rgba(34, 34, 34, 0.3);
}

.author-box:after {
  @extent .clrfix;
}

.comments-box {
  padding-bottom: 30px;
  /*border-bottom: 1px solid #E9E9E9;*/
}

.comments-box .media {
  /*padding: 20px;*/
  padding-bottom: 30px;
  border-bottom: 1px solid #E9E9E9;
  background-color: #fff;
  margin-bottom: 30px;
}

.comments-box .media .media-left {
  display: inline-table;
  float: left;
  padding-right: 20px;
}

.comments-box .media .media-left img {
  width: 60px;
  height: 60px;
}

.comments-box .media .media-body {
  margin-left: 140px;
}

.comments-box .media.comment {
  background-color: #fff;
}

.comments-box .media.reply-comment {
  /*background-color:#F6F6F6;*/
  margin-left: 40px;
}

.comments-box .media:after {
  display: table;
  content: " ";
  clear: both;
}

.comments-box .media-heading {
  color: #041e42;
  font-weight: 600;
}

.comments-box .comments-box .media-left img {
  width: 100px;
  height: 100px;
}

.comments-box .date {
  float: right;
  color: rgba(34, 34, 34, 0.3);
}

.comments-box .replay {
  margin-top: 5px;
}

.comments-box .replay a {
  color: rgba(34, 34, 34, 0.3);
}

.comments-box .replay a:hover {
  color: #ed1c24;
}

.comments-box .title-heading,
.leave-comment-box .title-heading {
  font-size: 18px;
  line-height: 25px;
  color: #041e42;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* 6.5 - Contact */
.form-comment .form-control,
.contact .form-control {
  height: 45px;
  background-color: #f6f6f6;
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: none;
}

.form-comment textarea.form-control,
.contact textarea.form-control {
  height: auto;
}

.form-comment .btn.disabled,
.form-comment .disabled.btn-primary,
.form-comment .disabled.btn-secondary,
.form-comment .btn[disabled],
.form-comment [disabled].btn-primary,
.form-comment [disabled].btn-secondary,
.form-comment fieldset[disabled] .btn,
.form-comment fieldset[disabled] .btn-primary,
.form-comment fieldset[disabled] .btn-secondary,
.contact .btn.disabled,
.contact .disabled.btn-primary,
.contact .disabled.btn-secondary,
.contact .btn[disabled],
.contact [disabled].btn-primary,
.contact [disabled].btn-secondary,
.contact fieldset[disabled] .btn,
.contact fieldset[disabled] .btn-primary,
.contact fieldset[disabled] .btn-secondary {
  opacity: 1;
}

.maps-wraper {
  position: relative;
}

.maps {
  width: 100%;
  height: 350px;
  display: block;
  background: #ccc;
  position: relative;
}

#google-container {
  position: relative;
  width: 100%;
  height: 500px;
  background-color: #fff;
}

#cd-google-map {
  position: relative;
  overflow: hidden;
}

#cd-google-map .col-md-12 {
  padding: 0;
}

#cd-google-map .address {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  background-color: rgba(5, 5, 5, 0.9);
}

#cd-google-map .address .address-item {
  position: relative;
  padding-left: 44px;
}

#cd-google-map .address .address-item i {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 4px;
  color: #fcfcfc;
  font-size: 22px;
  text-align: center;
  line-height: 24px;
}

#cd-zoom-in,
#cd-zoom-out {
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-left: 10px;
  background-color: #041e42;
  background-repeat: no-repeat;
  background-size: 32px 64px;
  background-image: url("/assets/images/cd-icon-controller.svg");
}

.no-touch #cd-zoom-in:hover,
.no-touch #cd-zoom-out:hover {
  background-color: #ed1c24;
}

#cd-zoom-in {
  background-position: 50% 0;
  margin-top: 10px;
  margin-bottom: 1px;
}

#cd-zoom-out {
  background-position: 50% -32px;
}

/* 6.6 Section - Features */
/* 6.7 Section - CTA */
.section.cta {
  background: url("/assets/images/1920x960_slider1.jpeg") bottom center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: 80px 0;
}

.section.cta .cta-info {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
}

.section.cta:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #041e42;
  opacity: .9;
}

/*.cta{
   background-color: $blue_1;
   padding: 80px 0;

   .cta-info{
     width: 60%;
     margin: 0 auto;
     text-align: center;
     font-family: $font_1;
     color: $white;
   }
 }*/
/* 6.8 - Price */
/* 6.9 - Partners */
.client-img {
  text-align: center;
  margin-bottom: 40px;
}

.client-img img {
  margin: 0 auto;
}

/* 6.10 - Testimony */
.people,
.user-pic {
  display: block;
  max-width: 100%;
}

#owl-testimony .owl-dots {
  display: none !important;
}

@media (max-width: 768px) {
  #owl-testimony .owl-nav {
    display: none !important;
  }

  #owl-testimony .owl-dots {
    display: block !important;
  }
}

.testimony {
  padding: 80px 0;
}

.testimony .owl-controls .owl-nav [class*=owl-] {
  color: #041e42;
  font-size: 14px;
  margin: 5px;
  background: transparent;
  display: inline-block;
  cursor: pointer;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  width: 40px;
  height: 40px;
  border: 3px solid #041e42;
  padding-top: 7px;
}

.testimony .owl-controls .owl-nav [class*=owl-]:hover {
  background: transparent;
  color: #ed1c24;
  border-color: #ed1c24;
  text-decoration: none;
}

.testimony .owl-nav {
  position: relative;
  z-index: 2;
}

.testimony .owl-nav .owl-prev,
.testimony .owl-nav .owl-next {
  position: absolute;
  top: -180px;
}

.testimony .owl-nav .owl-prev {
  left: -70px;
}

.testimony .owl-nav .owl-next {
  right: -70px;
}

/* 6.11 - 404 Page */
.title-404 {
  font-size: 5em;
  color: #041e42;
  margin-bottom: -20px;
}

.subtitle-404 {
  font-size: 2em;
  color: #46c2ca;
}

/* 6.12 - Pages */
.banner-page.pages {
  background: url("/assets/images/1920x960.jpg") bottom center no-repeat;
}

/* 6.12 - Contact */
.banner-page.contact {
  background: url("/assets/images/1920x960.jpg") bottom center no-repeat;
}

/**
  * 7. - FOOTER
  * -----------------------------------------------------------------------------
  */
.footer {
  padding: 200px 0 0 0;
  background-color: #041e42;
  color: #ffffff;
}

.footer>.container {
  padding-bottom: 50px;
}

.footer .footer-item .logo-bottom {
  margin-bottom: 10px;
}

.footer .footer-item .footer-title {
  font-size: 24px;
  font-weight: 600;
  padding: 20px 0 20px 0;
  border-top: 1px solid #073472;
  margin-bottom: 30px;
  position: relative;
}

.footer .footer-item .footer-title:before {
  content: '';
  display: block;
  width: 150px;
  border-top: 2px solid #ed1c24;
  position: absolute;
  left: 0;
  top: 0;
}

.footer .footer-item .footer-sosmed {
  margin-top: 50px;
  margin-bottom: 30px;
  display: inline-block;
}

.footer .footer-item .footer-sosmed a .item {
  color: #ffffff;
  display: table-cell;
  vertical-align: middle;
  margin: 0 14px;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #05295a;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
}

.footer .footer-item .footer-sosmed a .item:hover {
  background-color: #46c2ca;
}

.footer .footer-item .footer-sosmed a .item .fa {
  font-size: 16px;
}

.footer .footer-item .list {
  margin: 0;
  padding: 0;
}

.footer .footer-item .list li {
  list-style: none;
  margin: 14px 0;
}

.footer .footer-item .list li a {
  color: #46c2ca;
}

.footer .footer-item .list li a:hover {
  color: #ed1c24;
}

.footer .footer-item .footer-subscribe {
  position: relative;
}

.footer .footer-item .footer-subscribe input[type=email] {
  width: 100%;
  background: #fff;
  padding: 10px 57px 10px 17px;
  min-height: 38px;
  max-width: 480px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.footer .footer-item .footer-subscribe input[type=email]:focus {
  background: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 2px #ed1c24;
  -moz-box-shadow: 0 0 0 2px #ed1c24;
  box-shadow: 0 0 0 2px #ed1c24;
  border-color: transparent;
}

.footer .footer-item .footer-subscribe input[type=submit] {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}

.footer .footer-item .footer-subscribe label {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  width: 40px;
  height: 38px;
  line-height: 40px;
  padding: 0;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  background: none;
  color: #ffffff;
  outline: 0 !important;
  -webkit-transition: color .3s ease;
  -moz-transition: color .3s ease;
  -ms-transition: color .3s ease;
  -o-transition: color .3s ease;
  transition: color .3s ease;
  background: #ed1c24;
}

.footer .footer-item .footer-subscribe .icon {
  font-size: 18px;
}

.footer .footer-item .footer-subscribe p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  margin-top: 16px;
}

.footer .footer-item .recent-post {
  margin: 0;
  padding: 0;
}

.footer .footer-item .recent-post li {
  list-style: none;
  margin: 0 0 30px;
}

.footer .footer-item .recent-post li a {
  color: #fff;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 14px;
}

.footer .footer-item .recent-post li a:hover {
  color: #ed1c24;
}

.footer .footer-item .recent-post li .date {
  display: block;
  font-size: 12px;
  padding: 0;
  color: rgba(255, 255, 255, 0.7);
}

.footer .footer-item .recent-post li .date i {
  margin-right: 5px;
}

.footer .fcopy {
  padding: 30px 0;
  color: #ffffff;
  font-size: 14px;
  background-color: #041e42;
  border-top: 1px solid #073472;
}

.list-info {
  margin: 0;
  padding: 0;
}

.list-info li {
  margin-bottom: 20px;
  list-style: none;
}

.list-info li .info-icon {
  display: inline-block;
  color: #041e42;
  font-size: 16px;
  float: left;
}

.list-info li .info-text {
  margin-left: 30px;
}

/**
  * 8. - RESPONSIVE
  * -----------------------------------------------------------------------------
  */
@media (max-width: 1024px) {
  .slides-navigation a.prev {
    left: -20px;
  }

  .slides-navigation a.next {
    right: -20px;
  }

  .testimony .owl-nav .owl-prev {
    left: -20px;
  }

  .testimony .owl-nav .owl-next {
    right: -20px;
  }
}

@media (max-width: 768px) {
  .navbar-toggle {
    clear: left;
    float: right;
  }

  .topbar {
    height: auto;
  }

  .topbar-left {
    text-align: center;
  }

  .topbar-right {
    text-align: center !important;
  }

  .topbar-menu,
  .topbar-sosmed {
    margin-left: 0;
    margin-bottom: 0;
  }

  .nav>li>a {
    padding: 10px 6px;
  }

  /*.navbar-main .dropdown-menu > li a{
     color: #333333;
   }*/
  .box-icon-1 .icon {
    clear: left;
    display: block;
    width: 100%;
    text-align: center;
  }

  .box-icon-1 .body-content {
    text-align: center;
    margin-left: 0;
  }

  .container-nav .btn-orange-cta {
    position: absolute;
    right: 10px;
    top: 0;
  }

  .wrap-caption {
    width: 100%;
  }

  .box-team .box-image,
  .box-team .body-content {
    width: 100%;
  }

  .btn-sidebar.btn,
  .btn-sidebar.btn-primary,
  .btn-sidebar.btn-secondary,
  .btn-sidebar.btn-primary,
  .btn-sidebar.btn-secondary {
    padding: 15px 25px;
  }
}

@media (max-width: 479px) {
  .navbar-main {
    bottom: 0;
  }

  .navbar-main .navbar-brand {
    top: 7px;
  }

  .navbar-main .navbar-brand>img {
    width: 60%;
  }

  .navbar-main .dropdown-menu>li a {
    color: #222222;
  }

  .contact-info {
    clear: right;
    width: 100%;
    display: none;
  }

  .contact-info>div {
    display: block;
    margin-right: 0px;
  }

  /*.topbar-logo{
     .btn-cta{
         clear: right;
       position: relative;
        transform: translateY(78px);
       z-index: 4;
     }
   }*/
  .bos-quote .bos-photo {
    clear: left;
    display: block;
    width: auto;
    margin-bottom: 20px;
  }

  .bos-quote .bos-text {
    clear: left;
    display: block;
    margin-left: 0;
  }

  h2,
  .banner-page .title-page,
  .banner-page .title-page {
    font-size: 2em;
  }

  .box-partner .item .box-image {
    clear: left;
    width: auto;
  }

  .box-partner .item .box-info {
    clear: left;
    width: auto;
    margin-left: 0;
  }

  .career-tabs .nav-tabs>li {
    width: 100%;
    clear: left;
  }

  .nav>li>a {
    padding: 10px 25px;
  }

  .testimonial-1 .media {
    clear: both;
    display: block;
    max-width: 300px;
    margin-bottom: 40px;
    margin-right: 0;
  }

  .testimonial-1 .body {
    clear: both;
    display: block;
  }

  .cta .cta-info {
    width: 100%;
  }

  .wrap-caption .excerpt {
    font-size: small;
    line-height: 2.5rem;
  }

  .caption-heading {
    font-size: large;
  }

  .btn,
  .btn-primary,
  .btn-secondary {
    font-size: 12px;
    padding: 8px 28px;
    font-weight: 500;
  }
}

.caption-heading {
  font-size: x-large;
  font-weight: bold;
}

mat-icon {
  vertical-align: middle;
}

mat-form-field {
  width: 100%;
}

.cardboard-button {
  width: 100%;
  background: #22cb94;
  background: linear-gradient(to right, #22cb94, #12c1b3);
  border: 0;
  display: inline-block;
  line-height: 1;
  font-size: 16px;
  margin-top: 1rem;
  padding: 20px 23px;
  -webkit-border-radius: 3px;
  border-radius: 5px;
  color: #fff;
  fill: #fff;
  text-align: center;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  cursor: pointer;

  & mat-icon {
    vertical-align: middle;
  }

  // &:hover {
  //     background: linear-gradient(90deg, #2f64bd 0%, #234f9d 100%);
  // }

  &:disabled {
    background: rgb(34 203 148 / 38%) !important;
    cursor: not-allowed;
  }
}

/* Margin All Sides */
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

/* Margin Top */
.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

/* Margin Bottom */
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

/* Margin Left */
.me-0 {
  margin-left: 0 !important;
}

.me-1 {
  margin-left: 0.25rem !important;
}

.me-2 {
  margin-left: 0.5rem !important;
}

.me-3 {
  margin-left: 1rem !important;
}

.me-4 {
  margin-left: 1.5rem !important;
}

.me-5 {
  margin-left: 3rem !important;
}

.me-auto {
  margin-left: auto !important;
}

/* Margin Right */
.ms-0 {
  margin-right: 0 !important;
}

.ms-1 {
  margin-right: 0.25rem !important;
}

.ms-2 {
  margin-right: 0.5rem !important;
}

.ms-3 {
  margin-right: 1rem !important;
}

.ms-4 {
  margin-right: 1.5rem !important;
}

.ms-5 {
  margin-right: 3rem !important;
}

.ms-auto {
  margin-right: auto !important;
}

/* Margin Horizontal (left and right) */
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Margin Vertical (top and bottom) */
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

#slides {
  max-height: 650px;
}
